import styled, { keyframes } from 'styled-components';
import { colors, medias } from 'assets/styled/tokens';
import { Button } from 'components';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading-icon.svg';
export const LoadingContainer = styled.div`
  display: flex;
  width: 1050px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerTable = styled.div`
  display: none;
  @media (min-width: ${medias.desktopSm}) {
    display: block;
    padding: 0 24px 24px 24px;
    table {
      border-spacing: 0;
      border: 1px solid ${colors.whiteGray};
      width: 100%;
      border-radius: 8px 8px 0px 0px;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      td,
      th {
        margin: 0;
        border-bottom: 1px solid ${colors.whiteGray};
        //border-right: 1px solid ${colors.whiteGray};
        text-align: left;
        font-size: 14px;

        line-height: 14px;
        padding: 15px 10px;
        word-wrap: break-word;

        :nth-child(1),
        :nth-child(2),
        :nth-child(3),
        :nth-child(4),
        :nth-child(5) {
          width: 210px;
        }
        @media (max-width: ${medias.desktopMd}) {
          :nth-child(1),
          :nth-child(2),
          :nth-child(3),
          :nth-child(4) {
            width: 190px;
          }
          :nth-child(5) {
            width: 245px;
          }
        }

        :last-child {
          border-right: 0;
        }
      }

      th {
        font-weight: 500;
      }

      td {
        border-top: 0;
        color: ${colors.gray};
        vertical-align: top;

        .download-report {
          display: flex;
          align-content: center;
          align-items: center;
        }

        button {
          color: ${colors.primary};
          text-transform: uppercase;
          text-decoration: underline;
          margin-left: 10px;
          padding: 0;
          text-align: start;
        }

        svg {
          width: 15px;
          height: 15px;

          > path {
            fill: ${colors.primary};
          }
        }
      }
    }
  }
`;

export const TableRow = styled.tr`
  background-color: ${props => (props.gray ? '#fafafa' : '#ffffff')};
  :last-child {
    td {
      border-bottom: 0;
    }
  }
  :hover {
    background-color: #fff4e7;
    cursor: pointer;
  }
`;

export const TableCell = styled.td`
  border-top: 0;
  color: ${colors.gray};
  div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const StyledStatus = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  border: 1px solid ${props => props.borderColor};
  height: 32px;
  padding: 8px 12px 8px 12px;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  svg {
    margin-right: 8px;
    > path {
      fill: white !important;
    }
  }
  @media (max-width: ${medias.desktopMd}) {
    padding: 0;
    width: 100%;
  }
`;

export const CancelScheduleButton = styled(Button)`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-left: 12px;
  color: #1e1e1e !important;
  svg > path {
    fill: #1e1e1e !important;
  }
`;
export const Loading = styled.div`
  height: 310px;
  width: 1092px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #888888;
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Criando o componente SVG estilizado
export const RotatingCircle = styled(LoadingIcon)`
  animation: ${rotate} 2s linear infinite;
  width: 56px !important;
  height: 56px !important;
`;
export const ReScheduleButton = styled(Button)`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-left: 12px;
  color: #1e1e1e !important;
  display: flex;
  justify-content: center;
  align-items: center;
  svg > path {
    fill: #1e1e1e !important;
  }
  svg {
    width: 32px !important;
    height: 32px !important;
  }
`;
