import { CustomerPortalHeader } from 'components/Header/CustomerPortalHeader';
import {
  CleanFiltersButton,
  Content,
  ContentHeader,
  FiltersButton,
  OpenCallButton,
  Page,
  RadioFormGroup,
  SearchAndFilterContainer,
  StyledContainerHeader,
  StyledContainerSearchBar,
  StyledFormGroup,
  StyledFormRadio,
  StyledInnerGridColumn,
  TablecontainerWrapper,
  TableTitle,
  StyledContainerFooter,
  LeftContainerFooter,
  RightContainerFooter,
  Paginate,
  StyledDateRangePicker,
  MobileCleanFilterDiv,
  FooterContainer,
  NoOcurrenceScreen,
  ReloadButton,
} from './styled';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as CloseFilterIcon } from 'assets/icons/close-filter.svg';
import { ReactComponent as CleanFilterIcon } from 'assets/icons/cleanFiltersIcon.svg';
import { ReactComponent as LogoBlack } from 'assets/icons/logo-horizontal-black.svg';
import { ReactComponent as NotFoundIcon } from 'assets/icons/404-icon.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/loading-o-icon.svg';
import { Select } from 'components/Tables/ScheduleTable/Form/subcomponents/Select';
import { useState, useMemo, useEffect } from 'react';
import { Radio } from 'components/Form/subcomponents/Radio';
import CustomerPortalTable from 'components/Tables/CustomerPortalTable';
import { useMediaQuery } from 'react-responsive';
import { MobileCustomerPortalTable } from 'components/Tables/CustomerPortalTable/Mobile';
import { useCookies } from 'react-cookie';
import { ChamadoService } from 'services/main/endpoints/ChamadoService';
import { subMonths, format } from 'date-fns';
import { createGenericErrorToast, createErrorToast } from 'utils/createToast';
import { Toast } from 'components';
import { DateRangePicker } from 'components/Form/subcomponents/MobileDateRangePicker';
export const CustomerPortal = () => {
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [cookie] = useCookies();
  const [loading, setLoading] = useState(false);
  const [occurrenceNumber, setOccurrenceNumber] = useState(0);
  const [toastList, setToastList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itensPerPage, setItensPerPage] = useState(5);
  const initialPeriod = [subMonths(new Date(), 3), subMonths(new Date(), 0)];
  const {
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      chamadosType: 'meus',
      unidade: '',
      status: '',
      ambiente: '',
      equipamento: '',
      period: initialPeriod,
    },
  });
  const period = watch('period');
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const columns = useMemo(
    () => [
      { Header: 'Data/Hora de Abertura', accessor: 'data', width: 100 },
      { Header: 'Local / Equipamento', accessor: 'local', width: 300 },
      {
        Header: 'Nº Chamado / Descrição',
        accessor: 'chamado',
        width: 600,
      },
      { Header: 'Status', accessor: 'status', width: 200 },
    ],
    [],
  );
  const getDate = data => {
    const dateString = new Date(data);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
      <div>
        <div>{date}</div> <div>{time}</div>
      </div>
    );
  };
  const populateTableStrategy = async () => {
    const result =
      typeof period === 'string'
        ? period?.split('/')
        : `${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`?.split('/');
    const response = await ChamadoService.getOccurences({
      dataInicio: result[0],
      dataFim: result[1],
      pageSize: itensPerPage,
      pageIndex: pageNumber,
      empresa: cookie?.companies?.id,
    });
    return response?.data?.map(data => ({
      data: getDate(data.dataRegistro),
      status: data?.tipoUltimaCorrecao,
      chamado: data?.descricao,
      local: getLocation(data?.areaNome, data?.equipamentoNome, data?.siteNome),
      text: data?.statusNome,
      anomaliaGid: data?.anomaliaGid,
      cqa: data?.cqa,
    }));
  };
  const getLocation = (areaNome, equipamentoNome, siteNome) => {
    return (
      <>
        {siteNome && (
          <div>
            <b>Unidade:</b> {siteNome}
          </div>
        )}

        {areaNome && (
          <div>
            <b>Área:</b> {areaNome}
          </div>
        )}

        {equipamentoNome && (
          <div>
            <b>Equipamento:</b> {equipamentoNome}
          </div>
        )}
      </>
    );
  };

  const populateTable = async () => {
    try {
      const tableData = await populateTableStrategy();
      tableData?.plans?.reverse();
      setFields(tableData);
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      await populateTable();
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setLoading(false);
    }
    if (!cookie?.companies?.id) {
      createErrorToast(setToastList, 'Selecione uma empresa antes de continuar');
      return;
    }
  };
  const fetchOccurNumber = async () => {
    try {
      const date =
        typeof period === 'string'
          ? period?.split('/')
          : `${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`?.split('/');
      const result = await ChamadoService.getOcurrencesNumber({
        dataInicio: date[0],
        dataFim: date[1],
        empresa: cookie?.companies?.id,
      });
      setOccurrenceNumber(result.data);
    } catch {
      createGenericErrorToast(setToastList);
    }
  };
  useEffect(() => {
    if (cookie?.companies?.id) {
      fetchOccurNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, cookie?.companies?.id]);
  useEffect(() => {
    if (cookie?.companies?.id) {
      if (occurrenceNumber > 0) {
        fetchData();
        setIsFirstMount(false);
        getItensPerPage();
      }
    }

    // eslint-disable-next-line
  }, [errors, itensPerPage, pageNumber, period, cookie?.companies?.id, occurrenceNumber]);

  const [fields, setFields] = useState([]);
  const pageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const getItensPerPage = () => {
    setItensPerPage(getValues('pageItens'));
  };
  const pageCount1 = Math.ceil(occurrenceNumber / itensPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };
  const isDesktop = useMediaQuery({ minWidth: 1081 });
  return (
    <Page>
      <CustomerPortalHeader />
      <Content>
        <ContentHeader>
          <b>Boas vindas,</b> Aqui você pode Abrir um Chamado e acompanhar seu histórico de Chamados.
        </ContentHeader>

        <TablecontainerWrapper>
          {isDesktop && (
            <>
              <StyledContainerHeader>
                <TableTitle>Histórico de Chamados</TableTitle>
                <OpenCallButton>
                  <PlusIcon />
                  ABRIR UM CHAMADO
                </OpenCallButton>
              </StyledContainerHeader>
              <FieldRow marginBottom="1rem">
                <StyledInnerGridColumn size={2} columnGap="1rem">
                  <StyledFormGroup label={'Data de Abertura'} isRequired width={'38%'} inputSize={'85%'}>
                    <Controller
                      render={props => (
                        <StyledDateRangePicker
                          {...props}
                          data-cy="opening-of-occurrence-date-picker"
                          size="small"
                          placeholder="Seleção obrigatória"
                          required={false}
                          notClean={true}
                          buttonIsRequired={true}
                          value={`${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`}
                        />
                      )}
                      name="period"
                      control={control}
                    />
                  </StyledFormGroup>
                  <SearchAndFilterContainer>
                    <StyledContainerSearchBar placeholder={'Pesquisar'} /*onChange={handleSearch} */ />
                    {filterIsOpen && (
                      <CleanFiltersButton>
                        <CleanFilterIcon />
                        LIMPAR FILTROS
                      </CleanFiltersButton>
                    )}
                    <FiltersButton onClick={() => setFilterIsOpen(!filterIsOpen)}>
                      {filterIsOpen === true ? <CloseFilterIcon /> : <FilterIcon />}
                      FILTROS
                    </FiltersButton>
                    {!filterIsOpen && (
                      <ReloadButton onClick={fetchData}>
                        <ReloadIcon />
                        ATUALIZAR LISTA
                      </ReloadButton>
                    )}
                  </SearchAndFilterContainer>
                </StyledInnerGridColumn>
                {filterIsOpen && (
                  <>
                    <StyledInnerGridColumn size={1}>
                      <StyledFormGroup label={'Unidade'} width={'23%'} inputSize={'100%'}>
                        <Controller
                          name="unidade"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Ambiente'} width={'23%'} inputSize={'100%'}>
                        <Controller
                          name="ambiente"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Equipamento'} width={'23%'} inputSize={'100%'}>
                        <Controller
                          name="equipamento"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Status'} width={'23%'} inputSize={'100%'}>
                        <Controller
                          name="status"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                    </StyledInnerGridColumn>
                    <StyledInnerGridColumn size={1}>
                      <RadioFormGroup label={'Exibir:'}>
                        <StyledFormRadio>
                          <Radio
                            text="Chamados que eu abri"
                            type="radio"
                            id="meusChamados"
                            name="chamadosType"
                            value="meus"
                            ref={register()}
                          />
                          <Radio
                            text="Todos os chamados"
                            type="radio"
                            id="todosChamados"
                            name="chamadosType"
                            value="todos"
                            ref={register()}
                          />
                        </StyledFormRadio>
                      </RadioFormGroup>
                    </StyledInnerGridColumn>
                  </>
                )}
              </FieldRow>
              {occurrenceNumber === 0 ? (
                <NoOcurrenceScreen>
                  <NotFoundIcon />
                  <p>Nenhum chamado foi encontrado.</p>
                </NoOcurrenceScreen>
              ) : (
                <>
                  <CustomerPortalTable columns={columns} data={fields} loading={loading} />
                  <StyledContainerFooter>
                    <LeftContainerFooter>
                      <h3>Itens por página:</h3>
                      <FieldRow marginSides="16px">
                        <Controller
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              hasSearchBar={false}
                              options={pageOptions}
                              setValue={setValue}
                              defaultValue={isFirstMount ? props.defaultValue : 5}
                              onChange={value => {
                                props.onChange(value);
                                setPageNumber(1);
                                getItensPerPage();
                              }}
                              ref={null}
                            />
                          )}
                          name={'pageItens'}
                          control={control}
                          defaultValue={pageOptions[0].value}
                        />
                      </FieldRow>
                      <h4>Total de {occurrenceNumber} itens</h4>
                    </LeftContainerFooter>
                    <RightContainerFooter>
                      <Paginate
                        previousLabel={
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5 1L1 5L5 9"
                              stroke="#AAAAAA"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L5 5L1 9"
                              stroke="#AAAAAA"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        pageCount={pageCount1}
                        onPageChange={changePage}
                        breakLabel={'...'}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                      />
                    </RightContainerFooter>
                  </StyledContainerFooter>
                </>
              )}
            </>
          )}
          {!isDesktop && (
            <>
              <StyledContainerHeader>
                <TableTitle>Histórico de Chamados</TableTitle>
                <OpenCallButton>
                  <PlusIcon />
                  ABRIR UM CHAMADO
                </OpenCallButton>
                <FiltersButton onClick={() => setFilterIsOpen(!filterIsOpen)}>
                  {filterIsOpen === true ? <CloseFilterIcon /> : <FilterIcon />}
                  FILTROS
                </FiltersButton>
              </StyledContainerHeader>
              <FieldRow marginBottom="1rem">
                <StyledInnerGridColumn size={2} columnGap="1rem">
                  {filterIsOpen && (
                    <MobileCleanFilterDiv>
                      <CleanFiltersButton>
                        <CleanFilterIcon />
                        LIMPAR FILTROS
                      </CleanFiltersButton>
                    </MobileCleanFilterDiv>
                  )}
                  <StyledFormGroup label={'Data de Abertura'} isRequired width={'50%'} inputSize={'85%'}>
                    <Controller
                      render={props => (
                        <DateRangePicker
                          {...props}
                          data-cy="opening-of-occurrence-date-picker"
                          size="small"
                          notClean={true}
                          buttonIsRequired={true}
                          value={`${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`}
                          placeholder="Seleção obrigatória"
                          required={false}
                        />
                      )}
                      name="period"
                      control={control}
                      defaultValue={''}
                    />
                  </StyledFormGroup>
                  <SearchAndFilterContainer>
                    <StyledContainerSearchBar placeholder={'Pesquisar'} /*onChange={handleSearch} */ />
                  </SearchAndFilterContainer>
                </StyledInnerGridColumn>
                {filterIsOpen && (
                  <>
                    <StyledInnerGridColumn size={1}>
                      <StyledFormGroup label={'Unidade'} width={'283px'} inputSize={'100%'}>
                        <Controller
                          name="unidade"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Ambiente'} width={'283px'} inputSize={'100%'}>
                        <Controller
                          name="ambiente"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Equipamento'} width={'283px'} inputSize={'100%'}>
                        <Controller
                          name="equipamento"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label={'Status'} width={'200px'} inputSize={'100%'}>
                        <Controller
                          name="status"
                          control={control}
                          //defaultValue={fields?.companie?.cidade}
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              inputDifferent={true}
                              placeholder="Seleção Opcional"
                              //options={cities}
                              // onInputChange={handleChange}
                              //onChange={value => {
                              //handleChange(value);
                              //props.onChange(value);
                              //fetchData();
                              //}}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                    </StyledInnerGridColumn>
                    <StyledInnerGridColumn size={1}>
                      <RadioFormGroup label={'Exibir:'}>
                        <StyledFormRadio>
                          <Radio
                            text="Chamados que eu abri"
                            type="radio"
                            id="meusChamados"
                            name="chamadosType"
                            value="meus"
                            ref={register()}
                          />
                          <Radio
                            text="Todos os chamados"
                            type="radio"
                            id="todosChamados"
                            name="chamadosType"
                            value="todos"
                            ref={register()}
                          />
                        </StyledFormRadio>
                      </RadioFormGroup>
                    </StyledInnerGridColumn>
                  </>
                )}
                <StyledInnerGridColumn>
                  <ReloadButton onClick={fetchData}>
                    <ReloadIcon />
                    ATUALIZAR LISTA
                  </ReloadButton>
                </StyledInnerGridColumn>
              </FieldRow>
              {occurrenceNumber === 0 ? (
                <NoOcurrenceScreen>
                  <NotFoundIcon />
                  <p>Nenhum chamado foi encontrado.</p>
                </NoOcurrenceScreen>
              ) : (
                <>
                  <MobileCustomerPortalTable columns={columns} data={fields} loading={loading} />
                  <StyledContainerFooter>
                    <LeftContainerFooter>
                      <h3>Itens por página:</h3>
                      <FieldRow marginSides="16px">
                        <Controller
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              options={pageOptions}
                              setValue={setValue}
                              defaultValue={isFirstMount ? props.defaultValue : 5}
                              onChange={value => {
                                props.onChange(value);
                                setPageNumber(0);
                                getItensPerPage();
                              }}
                              ref={null}
                            />
                          )}
                          name={'pageItens'}
                          control={control}
                          defaultValue={pageOptions[0].value}
                        />
                      </FieldRow>
                      {/* <h4>Total de {fields.length} itens</h4> */}
                    </LeftContainerFooter>
                    <RightContainerFooter>
                      <Paginate
                        previousLabel={
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5 1L1 5L5 9"
                              stroke="#AAAAAA"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L5 5L1 9"
                              stroke="#AAAAAA"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        pageCount={pageCount1}
                        onPageChange={changePage}
                        breakLabel={'...'}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                      />
                    </RightContainerFooter>
                  </StyledContainerFooter>
                </>
              )}
            </>
          )}
        </TablecontainerWrapper>
        <FooterContainer>
          <LogoBlack />
          <p>Todos os direitos reservados.</p>
        </FooterContainer>
      </Content>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </Page>
  );
};
