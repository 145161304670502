import { AxiosGlobalFilters } from '../config';

export const ChamadoService = {
  getOccurences: ({ dataInicio, dataFim, pageSize, pageIndex, empresa }) => {
    const url = `/v1/ocorrencias?DataInicio=${dataInicio}&DataTermino=${dataFim}&PAgeSize=${pageSize}&PageIndex=${pageIndex}`;
    return AxiosGlobalFilters({
      method: 'get',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getOcurrencesNumber: ({ dataInicio, dataFim, empresa }) => {
    const url = `/v1/ocorrencias/count?DataInicio=${dataInicio}&DataTermino=${dataFim}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getOcurrencesData: ({ anomaliaGid, empresa }) => {
    const url = `/v1/ocorrencias/${anomaliaGid}/toedit`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getCorrections: ({ anomaliaGid, empresa }) => {
    const url = `/v3/correcoes?ocorrenciaId=${anomaliaGid}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
};
