import { useEffect, useState } from 'react';
import {
  TransparentBg,
  Modal,
  ModalHeader,
  OccurTitle,
  CloseModalButton,
  OccurContainer,
  OccurDetails,
  DetailsRows,
  DetailsCells,
  CorrectionsDetails,
  CorrectionsTitle,
  CorrectionsCardsDiv,
  CorrectionCard,
  CorrectionHeader,
  CorrectionRow,
  CorrectionCell,
  //AuthCallButton,
  SeePhotsButton,
  Loading,
  RotatingCircle,
} from './styled';
import { useCookies } from 'react-cookie';
import { ChamadoService } from 'services/main/endpoints/ChamadoService';
import { PhotosModal } from '../PhotosModal';
//chamados-32 inicio
export const OccurrenceModal = ({ setIsOpen, anomaliaGid, cqa }) => {
  const [cookie] = useCookies();
  const [isLoading, setIsLoading] = useState(true);
  const [isPhotosOpen, setIsPhotosOpen] = useState(false);
  const [occurenceData, setOcurrenceData] = useState({});
  const [corrections, setCorrections] = useState([]);
  const [photos, setPhotos] = useState([]);
  const handleOverlayClick = (e, setState) => {
    if (e.target === e.currentTarget) {
      setState(false);
    }
  };
  const fetchData = async () => {
    const params = { anomaliaGid, empresa: cookie?.companies?.id };
    setIsLoading(true);
    try {
      // Busca os dados de ocorrências e correções
      const response = await ChamadoService.getOcurrencesData(params);
      setOcurrenceData(response.data);
      const result = await ChamadoService.getCorrections(params);
      setCorrections(result?.data);
    } catch (error) {
      console.error('Erro fetching data;', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomaliaGid, cookie?.companies?.id]);
  const getDate = data => {
    if (data === null) {
      return null;
    }
    const dateString = new Date(data);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${date} • ${time} `;
  };
  const getLocation = (siteNome, grupoAreaNome, subgrupoAreaNome, areaNome, equipamentoNome) => {
    return [siteNome, grupoAreaNome, subgrupoAreaNome, areaNome, equipamentoNome].filter(Boolean).join(' / ');
  };
  const statusMapping = {
    4: 0,
    3: 1,
  };
  const statusColors = [
    {
      text: 'Agendada',
      textColor: '#286892',
      borderColor: '#85BFE4',
      backgroundColor: '#CCE4FA',
      iconColor: '#888888',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#307CAE" />
          <path
            d="M3 8.36719C3 8.72314 3.28125 9 3.64286 9H8.35714C8.70536 9 9 8.72314 9 8.36719V4.78125H3V8.36719ZM7.28571 5.7832C7.28571 5.7041 7.35268 5.625 7.44643 5.625H7.98214C8.0625 5.625 8.14286 5.7041 8.14286 5.7832V6.31055C8.14286 6.40283 8.0625 6.46875 7.98214 6.46875H7.44643C7.35268 6.46875 7.28571 6.40283 7.28571 6.31055V5.7832ZM7.28571 7.4707C7.28571 7.3916 7.35268 7.3125 7.44643 7.3125H7.98214C8.0625 7.3125 8.14286 7.3916 8.14286 7.4707V7.99805C8.14286 8.09033 8.0625 8.15625 7.98214 8.15625H7.44643C7.35268 8.15625 7.28571 8.09033 7.28571 7.99805V7.4707ZM5.57143 5.7832C5.57143 5.7041 5.63839 5.625 5.73214 5.625H6.26786C6.34821 5.625 6.42857 5.7041 6.42857 5.7832V6.31055C6.42857 6.40283 6.34821 6.46875 6.26786 6.46875H5.73214C5.63839 6.46875 5.57143 6.40283 5.57143 6.31055V5.7832ZM5.57143 7.4707C5.57143 7.3916 5.63839 7.3125 5.73214 7.3125H6.26786C6.34821 7.3125 6.42857 7.3916 6.42857 7.4707V7.99805C6.42857 8.09033 6.34821 8.15625 6.26786 8.15625H5.73214C5.63839 8.15625 5.57143 8.09033 5.57143 7.99805V7.4707ZM3.85714 5.7832C3.85714 5.7041 3.92411 5.625 4.01786 5.625H4.55357C4.63393 5.625 4.71429 5.7041 4.71429 5.7832V6.31055C4.71429 6.40283 4.63393 6.46875 4.55357 6.46875H4.01786C3.92411 6.46875 3.85714 6.40283 3.85714 6.31055V5.7832ZM3.85714 7.4707C3.85714 7.3916 3.92411 7.3125 4.01786 7.3125H4.55357C4.63393 7.3125 4.71429 7.3916 4.71429 7.4707V7.99805C4.71429 8.09033 4.63393 8.15625 4.55357 8.15625H4.01786C3.92411 8.15625 3.85714 8.09033 3.85714 7.99805V7.4707ZM8.35714 3.09375H7.71429V2.46094C7.71429 2.35547 7.60714 2.25 7.5 2.25H7.07143C6.95089 2.25 6.85714 2.35547 6.85714 2.46094V3.09375H5.14286V2.46094C5.14286 2.35547 5.03571 2.25 4.92857 2.25H4.5C4.37946 2.25 4.28571 2.35547 4.28571 2.46094V3.09375H3.64286C3.28125 3.09375 3 3.38379 3 3.72656V4.35938H9V3.72656C9 3.38379 8.70536 3.09375 8.35714 3.09375Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Solucionada',
      textColor: '#00715C',
      borderColor: '#63CAB7',
      backgroundColor: '#B4EDE3',
      iconColor: '#00715C',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#00866E" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Agendado com sucesso',
      textColor: '#985600',
      borderColor: '#FFB555',
      backgroundColor: '#FCE3C2',
      iconColor: '#00866E',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#DC7D00" />
          <path
            d="M4.66602 2.37172L4.90844 5.68547C4.90844 5.89516 5.02965 6 5.27208 6H6.72662C6.96905 6 7.09026 5.89516 7.09026 5.68547L7.33268 2.37172C7.33268 2.25735 7.29228 2.17156 7.21147 2.11438C7.15086 2.03813 7.07006 2 6.96905 2H5.02965C4.92864 2 4.83773 2.03813 4.75692 2.11438C4.69632 2.17156 4.66602 2.25735 4.66602 2.37172Z"
            fill="white"
          />
          <path
            d="M5.99935 7.33398C6.3665 7.33398 6.67568 7.46925 6.92689 7.73978C7.19742 7.99099 7.33268 8.30017 7.33268 8.66732C7.33268 9.03447 7.19742 9.35331 6.92689 9.62384C6.67568 9.87505 6.3665 10.0007 5.99935 10.0007C5.6322 10.0007 5.31336 9.87505 5.04283 9.62384C4.79162 9.35331 4.66602 9.03447 4.66602 8.66732C4.66602 8.30017 4.79162 7.99099 5.04283 7.73978C5.31336 7.46925 5.6322 7.33398 5.99935 7.33398Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];
  const getStatusColor = (status, nome) => {
    if (nome === 'Solucionada') {
      return statusColors[1];
    }
    if (statusMapping.hasOwnProperty(status)) {
      return statusColors[statusMapping[status]];
    }
    return statusColors[2];
  };
  const openFotosModal = photos => {
    setPhotos(photos);
    setIsPhotosOpen(true);
  };
  return (
    <>
      <TransparentBg onClick={e => handleOverlayClick(e, setIsOpen)}>
        <Modal height={isLoading ? '400px' : '100%'}>
          <ModalHeader>
            <OccurTitle>Chamado Nº {cqa}</OccurTitle>
            <CloseModalButton onClick={() => setIsOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M1 1L15 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
                <path d="M15 1L1 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </CloseModalButton>
          </ModalHeader>
          {isLoading ? (
            <Loading>
              <RotatingCircle />
              <p>Carregando dados...</p>
            </Loading>
          ) : (
            <OccurContainer>
              <OccurDetails>
                <img src={process.env.REACT_APP_URL_IMAGES + occurenceData?.fotoPath} alt="imagem" />
                <div>
                  <DetailsRows>
                    <div>
                      <DetailsCells>
                        <b>Data/Hora de Abertura</b>
                        {getDate(occurenceData?.dataRegistro)}
                      </DetailsCells>
                      <DetailsCells>
                        <b>Data Limite</b>
                        {getDate(occurenceData?.dataLimiteExecucao) || ''}
                      </DetailsCells>
                    </div>
                    <DetailsCells>
                      <b>Prioridade</b>
                      {occurenceData?.prioridadeDescricao || ''}
                    </DetailsCells>
                    <DetailsCells>
                      <b>Solicitante da execução</b>
                      {occurenceData?.emitentes?.map(emitente => emitente?.usuarioNome + '; ') || ''}
                    </DetailsCells>
                  </DetailsRows>
                  <DetailsRows>
                    <DetailsCells>
                      <b>Local / Equipamento</b>
                      {getLocation(
                        occurenceData?.siteNome,
                        occurenceData?.grupoAreaNome,
                        occurenceData?.subgrupoAreaNome,
                        occurenceData?.areaNome,
                        occurenceData?.equipamentoNome,
                      )}
                    </DetailsCells>
                  </DetailsRows>
                  <DetailsRows>
                    <DetailsCells>
                      <b>Sistema</b>
                      {occurenceData?.sistemEmpresaNome || ' '}
                    </DetailsCells>
                    <DetailsCells>
                      <b>Setor</b>
                      {occurenceData?.setorNome || ''}
                    </DetailsCells>
                  </DetailsRows>
                  <DetailsRows>
                    <DetailsCells>
                      <b>Descrição</b>
                      {occurenceData?.descricao || ''}
                    </DetailsCells>
                  </DetailsRows>
                </div>
              </OccurDetails>
              <CorrectionsDetails height={isLoading ? '100px' : 'auto'}>
                <CorrectionsTitle>Correções realizadas</CorrectionsTitle>
                <CorrectionsCardsDiv height={isLoading ? '100px' : '100%'}>
                  {corrections?.map((correction, index) => {
                    const colors = getStatusColor(Number(correction?.codigoTipoCorrecao), correction?.tipoCorrecaoNome);
                    return (
                      <CorrectionCard key={index}>
                        <CorrectionHeader
                          backgroundColor={colors.backgroundColor}
                          textColor={colors.textColor}
                          borderColor={colors.borderColor}
                        >
                          <div>
                            {colors.icon}
                            {correction?.tipoCorrecaoNome}
                          </div>
                          {/* Esse botaõ vai ser implementado depois*/}
                          {/* <AuthCallButton>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <circle cx="6" cy="6" r="6" fill="white" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.53651 8.81249C5.28206 9.06246 4.85747 9.06246 4.60302 8.81249L2.19023 6.4422C1.93659 6.19303 1.93659 5.77838 2.19023 5.5292L2.71349 5.01517C2.96778 4.76536 3.37748 4.7652 3.63196 5.0147L5.07616 6.4209L8.36756 3.18747C8.622 2.93751 9.03206 2.93751 9.2865 3.18747L9.80976 3.70151C10.0634 3.95069 10.0634 4.36534 9.80976 4.61451L5.53651 8.81249Z"
                              fill="#F38A00"
                            />
                          </svg>
                          Clique para autorizar
                        </AuthCallButton> */}
                          {correction?.fotosPathsList && (
                            <SeePhotsButton onClick={() => openFotosModal(correction?.fotosPathsList)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="12"
                                viewBox="0 0 14 12"
                                fill="none"
                              >
                                <path
                                  d="M14 3V10.7143C14 11.4375 13.3984 12 12.6875 12H1.3125C0.574219 12 0 11.4375 0 10.7143V3C0 2.30357 0.574219 1.71429 1.3125 1.71429H3.71875L4.04688 0.857143C4.23828 0.348214 4.73047 0 5.27734 0H8.69531C9.24219 0 9.73438 0.348214 9.92578 0.857143L10.2812 1.71429H12.6875C13.3984 1.71429 14 2.30357 14 3ZM10.2812 6.85714C10.2812 5.08929 8.80469 3.64286 7 3.64286C5.16797 3.64286 3.71875 5.08929 3.71875 6.85714C3.71875 8.65179 5.16797 10.0714 7 10.0714C8.80469 10.0714 10.2812 8.65179 10.2812 6.85714ZM9.40625 6.85714C9.40625 8.16964 8.3125 9.21429 7 9.21429C5.66016 9.21429 4.59375 8.16964 4.59375 6.85714C4.59375 5.57143 5.66016 4.5 7 4.5C8.3125 4.5 9.40625 5.57143 9.40625 6.85714Z"
                                  fill="#AAAAAA"
                                />
                              </svg>
                              Ver fotos
                            </SeePhotsButton>
                          )}
                        </CorrectionHeader>
                        <CorrectionRow>
                          <CorrectionCell>
                            <b>Data/Hora de Início</b>
                            {getDate(correction?.inicio) || ' '}
                          </CorrectionCell>
                          <CorrectionCell>
                            <b>Data/Hora de Término</b>
                            {getDate(correction?.termino) || ' '}
                          </CorrectionCell>
                          <CorrectionCell>
                            <b>Executor</b>
                            {correction?.executoresNomes
                              ? correction.executoresNomes
                                  .split(',')
                                  .map((nome, index) => <span key={index}>{nome}</span>)
                              : ' '}
                          </CorrectionCell>
                          <CorrectionCell>
                            <b>Descrição</b>
                            {correction?.descricao}
                          </CorrectionCell>
                        </CorrectionRow>
                      </CorrectionCard>
                    );
                  })}
                </CorrectionsCardsDiv>
              </CorrectionsDetails>
            </OccurContainer>
          )}
        </Modal>
      </TransparentBg>

      {isPhotosOpen && <PhotosModal photos={photos} setIsPhotosOpen={setIsPhotosOpen} />}
    </>
  );
};
