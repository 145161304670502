import styled from 'styled-components';
import { medias, fonts } from 'assets/styled/tokens';

import lupaIconSvg from 'assets/icons/magnifying.svg';
import { Button } from 'components';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import ReactPaginate from 'react-paginate';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
export const Paginate = styled(ReactPaginate).attrs({
  className: 'paginationBtn',
  activeClassName: 'paginationActive',
  previousLinkClassName: 'previousBtn',
  nextLinkClassName: 'nextBtn',
  pageLinkClassName: 'pageBtn',
})`
  height: 40px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #888888;
    cursor: pointer;

    &:hover {
      color: #1e1e1e;
      background-color: #f5f3f1;
    }
  }
  .paginationActive {
    color: #1e1e1e;
    background-color: #f5f3f1;
    font-weight: 700;
  }
  .previousBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
  .nextBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
  .pageBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
`;
export const StyledContainerFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 15px 15px 15px;
  flex-direction: column;
  @media (min-width: ${medias.desktopSm}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LeftContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  @media (min-width: ${medias.desktopSm}) {
    justify-content: center;
    margin-bottom: 0px;
  }
  div {
    margin: 0 10px;
  }
  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #1e1e1e;
  }
  h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
  }
`;
export const RightContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: ${medias.desktopSm}) {
    justify-content: flex-end;
  }
`;

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f5f3f1;
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  @media (min-width: ${medias.desktopSm}) {
    justify-content: center;
    align-items: center;
    padding: 0 10%;
  }
`;
export const MobileCleanFilterDiv = styled.div`
  margin: 10px 0;
  width: 100%;
`;
export const ContentHeader = styled.div`
  padding: 40px 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #888888;
  width: 100%;
  b {
    font-family: Blinker;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    text-align: left;
    color: #1e1e1e;
    margin-right: 5px;
  }
`;
export const TablecontainerWrapper = styled.div`
  border: 1px solid #e5e5e5;
  height: auto;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
`;
export const StyledContainerHeader = styled.div`
  background: #ffffff;
  padding: 20px 30px 0px 30px;
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  // padding: 0 0 0 0;
  margin-bottom: 52px;
  @media (min-width: ${medias.desktopSm}) {
    position: inherit;
    margin-bottom: 0;
  }
`;
export const TableTitle = styled.div`
  padding: 0px 0px 20px 0px;
  border-bottom: 2px solid #f38a00;
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #f38a00;
`;
export const StyledContainerSearchBar = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-left: 10px;

  ::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
    padding: 6px 6px 6px 0;
    background: url(${lupaIconSvg}) no-repeat center/contain;
    background-position: right center;
    background-size: 12px;
    background-position-x: 95%;
  }
  @media (min-width: ${medias.desktopSm}) {
    margin-right: 20px;
    width: 240px;
    top: auto;
  }
`;
export const OpenCallButton = styled(Button)`
  position: absolute;
  top: 92px;
  padding: 9px;
  width: 160px;
  height: 32px;
  justify-content: space-between;
  @media (min-width: ${medias.desktopSm}) {
    position: inherit;
    top: auto;
    width: 190px;
    height: 32px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 20px;
    svg {
      margin-right: 5px;
    }
  }
`;
export const StyledInnerGridColumn = styled.div`
  display: flex;
  width: 100%;
  //align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 15px 30px 10px 30px;
  @media (min-width: ${medias.desktopSm}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
  margin-bottom: 15px;
  input {
    width: 100%;
  }

  @media (min-width: ${medias.desktopSm}) {
    width: ${props => props.width};
    input {
      width: ${props => props.inputSize};
    }
    margin-bottom: 0;
  }
`;
export const StyledDateRangePicker = styled(DateRangePicker)`
  height: 32px;
  > div input {
    margin-bottom: 0 !important;
    height: 100%;
  }
  div svg {
    transform: translate(0px, -10%) !important;
  }
  @media (min-width: ${medias.desktopSm}) {
    div svg {
      transform: translate(0px, -10%) !important;
    }
  }
`;
export const RadioFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  div {
    margin-left: 0;
    justify-content: flex-start;
    label:last-of-type {
      margin-left: 0;
    }
  }
  @media (min-width: ${medias.desktopSm}) {
    align-items: center;
    flex-direction: row;
    div {
      margin-left: 15px;
      justify-content: flex-start;
      label:last-of-type {
        margin-left: 0.9rem;
      }
    }
  }
`;
export const StyledFormRadio = styled.div`
  margin-bottom: 4px;
  margin-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 0.75rem;

  label {
    margin-left: 0.9375rem;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 0.3125rem;
      cursor: pointer;
    }
  }
`;
export const SearchAndFilterContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  @media (min-width: ${medias.desktopSm}) {
    width: 53%;
  }
`;
export const ReloadButton = styled.button`
  border: 1px solid #f38a00;
  color: #f38a00;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 4px;
  height: 32px;
  width: 167px;
`;
export const FiltersButton = styled.button`
  position: absolute;
  top: 92px;
  right: 9%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #1e1e1e;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 101px;
  height: 32px;
  padding: 10px;
  @media (min-width: ${medias.desktopSm}) {
    position: inherit;
    top: auto;
    right: auto;
    margin-right: 20px;
  }
`;
export const CleanFiltersButton = styled.button`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #c25446;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 156px;
  padding: 8px;
  margin-right: 20px;
`;
export const FooterContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  > p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #888888;
  }
`;

export const NoOcurrenceScreen = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-family: Blinker;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #aaaaaa;
    margin-top: 20px;
  }
`;
