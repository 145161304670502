import { ChamadoBox, Status, TableContainer, Loading, RotatingCircle } from './styled';
import { useState } from 'react';
import { OccurrenceModal } from '../components/OccurrenceModal';
export const MobileCustomerPortalTable = ({ columns, data, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anomaliaGid, setAnomaliaGid] = useState(null);
  const [cqa, setCqa] = useState(' ');
  const statusMapping = {
    null: 2,
    4: 0,
    3: 1,
  };
  const statusColors = [
    {
      text: 'Agendada',
      textColor: '#286892',
      borderColor: '#85BFE4',
      backgroundColor: '#CCE4FA',
      iconColor: '#888888',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#307CAE" />
          <path
            d="M3 8.36719C3 8.72314 3.28125 9 3.64286 9H8.35714C8.70536 9 9 8.72314 9 8.36719V4.78125H3V8.36719ZM7.28571 5.7832C7.28571 5.7041 7.35268 5.625 7.44643 5.625H7.98214C8.0625 5.625 8.14286 5.7041 8.14286 5.7832V6.31055C8.14286 6.40283 8.0625 6.46875 7.98214 6.46875H7.44643C7.35268 6.46875 7.28571 6.40283 7.28571 6.31055V5.7832ZM7.28571 7.4707C7.28571 7.3916 7.35268 7.3125 7.44643 7.3125H7.98214C8.0625 7.3125 8.14286 7.3916 8.14286 7.4707V7.99805C8.14286 8.09033 8.0625 8.15625 7.98214 8.15625H7.44643C7.35268 8.15625 7.28571 8.09033 7.28571 7.99805V7.4707ZM5.57143 5.7832C5.57143 5.7041 5.63839 5.625 5.73214 5.625H6.26786C6.34821 5.625 6.42857 5.7041 6.42857 5.7832V6.31055C6.42857 6.40283 6.34821 6.46875 6.26786 6.46875H5.73214C5.63839 6.46875 5.57143 6.40283 5.57143 6.31055V5.7832ZM5.57143 7.4707C5.57143 7.3916 5.63839 7.3125 5.73214 7.3125H6.26786C6.34821 7.3125 6.42857 7.3916 6.42857 7.4707V7.99805C6.42857 8.09033 6.34821 8.15625 6.26786 8.15625H5.73214C5.63839 8.15625 5.57143 8.09033 5.57143 7.99805V7.4707ZM3.85714 5.7832C3.85714 5.7041 3.92411 5.625 4.01786 5.625H4.55357C4.63393 5.625 4.71429 5.7041 4.71429 5.7832V6.31055C4.71429 6.40283 4.63393 6.46875 4.55357 6.46875H4.01786C3.92411 6.46875 3.85714 6.40283 3.85714 6.31055V5.7832ZM3.85714 7.4707C3.85714 7.3916 3.92411 7.3125 4.01786 7.3125H4.55357C4.63393 7.3125 4.71429 7.3916 4.71429 7.4707V7.99805C4.71429 8.09033 4.63393 8.15625 4.55357 8.15625H4.01786C3.92411 8.15625 3.85714 8.09033 3.85714 7.99805V7.4707ZM8.35714 3.09375H7.71429V2.46094C7.71429 2.35547 7.60714 2.25 7.5 2.25H7.07143C6.95089 2.25 6.85714 2.35547 6.85714 2.46094V3.09375H5.14286V2.46094C5.14286 2.35547 5.03571 2.25 4.92857 2.25H4.5C4.37946 2.25 4.28571 2.35547 4.28571 2.46094V3.09375H3.64286C3.28125 3.09375 3 3.38379 3 3.72656V4.35938H9V3.72656C9 3.38379 8.70536 3.09375 8.35714 3.09375Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Solucionada',
      textColor: '#00715C',
      borderColor: '#63CAB7',
      backgroundColor: '#B4EDE3',
      iconColor: '#00715C',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#00866E" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Não analisada',
      textColor: '#A3463B',
      borderColor: '#F4A399',
      backgroundColor: '#FFDBD6',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#EC6655" />
          <path
            d="M7.97365 8.9172C7.85493 9.03592 7.66836 9.01896 7.5666 8.9172L6.00624 7.35683L4.44588 8.9172C4.32715 9.03592 4.14059 9.01896 4.03883 8.9172L3.08904 7.96741C2.97032 7.84869 2.97032 7.67908 3.08904 7.56036L4.6494 6L3.08904 4.43964C2.97032 4.32092 2.97032 4.15131 3.08904 4.03259L4.03883 3.0828C4.14059 2.98104 4.32715 2.96408 4.44588 3.0828L6.00624 4.64316L7.5666 3.0828C7.66836 2.98104 7.85493 2.96408 7.97365 3.0828L8.92343 4.03259C9.0252 4.13435 9.0252 4.33788 8.92343 4.43964L7.36307 6L8.92343 7.56036C9.0252 7.66212 9.0252 7.86565 8.92343 7.96741L7.97365 8.9172Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Agendado com sucesso',
      textColor: '#985600',
      borderColor: '#FFB555',
      backgroundColor: '#FCE3C2',
      iconColor: '#00866E',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#DC7D00" />
          <path
            d="M4.66602 2.37172L4.90844 5.68547C4.90844 5.89516 5.02965 6 5.27208 6H6.72662C6.96905 6 7.09026 5.89516 7.09026 5.68547L7.33268 2.37172C7.33268 2.25735 7.29228 2.17156 7.21147 2.11438C7.15086 2.03813 7.07006 2 6.96905 2H5.02965C4.92864 2 4.83773 2.03813 4.75692 2.11438C4.69632 2.17156 4.66602 2.25735 4.66602 2.37172Z"
            fill="white"
          />
          <path
            d="M5.99935 7.33398C6.3665 7.33398 6.67568 7.46925 6.92689 7.73978C7.19742 7.99099 7.33268 8.30017 7.33268 8.66732C7.33268 9.03447 7.19742 9.35331 6.92689 9.62384C6.67568 9.87505 6.3665 10.0007 5.99935 10.0007C5.6322 10.0007 5.31336 9.87505 5.04283 9.62384C4.79162 9.35331 4.66602 9.03447 4.66602 8.66732C4.66602 8.30017 4.79162 7.99099 5.04283 7.73978C5.31336 7.46925 5.6322 7.33398 5.99935 7.33398Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];
  const getStatusColor = status => {
    if (statusMapping.hasOwnProperty(status)) {
      return statusColors[statusMapping[status]];
    }
    return statusColors[3];
  };
  const openModal = (anomaliaGid, cqa) => {
    setAnomaliaGid(anomaliaGid);
    setCqa(cqa);
    setIsOpen(true);
  };
  return (
    <TableContainer>
      {loading === true ? (
        <Loading>
          <RotatingCircle />
          <p>Carregando dados...</p>
        </Loading>
      ) : (
        data.map((item, index) => (
          <ChamadoBox key={index} even={index % 2 === 0} onClick={() => openModal(item?.anomaliaGid, item.cqa)}>
            {columns.map(header => (
              <div key={header.accessor}>
                <h1>{header.Header}</h1>
                {header.accessor === 'status' ? (
                  <Status
                    textColor={getStatusColor(item[header.accessor]).textColor}
                    backgroundColor={getStatusColor(item[header.accessor]).backgroundColor}
                    borderColor={getStatusColor(item[header.accessor]).borderColor}
                  >
                    {getStatusColor(item[header.accessor]).icon}
                    {item?.text}
                  </Status>
                ) : header?.accessor === 'chamado' ? (
                  <div>
                    <b>{item?.cqa}: </b>
                    {item[header.accessor]}
                  </div>
                ) : (
                  <div>{item[header.accessor]}</div>
                )}
              </div>
            ))}
          </ChamadoBox>
        ))
      )}
      {isOpen && <OccurrenceModal setIsOpen={setIsOpen} anomaliaGid={anomaliaGid} cqa={cqa} />}
    </TableContainer>
  );
};
