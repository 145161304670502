import styled, { keyframes } from 'styled-components';
import { medias } from 'assets/styled/tokens';
//import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { GridColumn } from 'components';

import { ReactComponent as LoadingIcon } from 'assets/icons/loading-icon.svg';
//chamados-32 inicio
export const TransparentBg = styled.div`
  background: rgba(0, 0, 0, 0.7);
  //opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px 0;
  z-index: 5;
`;

export const Modal = styled.div`
  background-color: #ffffff;
  //padding: 20px;
  height: ${props => props.height};
  width: 90%;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @media (min-width: ${medias.desktopSm}) {
    width: 1170px;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
export const OccurTitle = styled.h1`
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1e1e1e;
`;
export const CloseModalButton = styled.button`
  border: none;
`;
export const OccurContainer = styled.div`
  padding: 20px;
  max-width: 100%;
  height: calc(92% + 6px);
  overflow-y: scroll;
`;
export const OccurDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  img {
    width: 192px;
    height: 192px;
    //border: 1px solid #000;
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 192px;
    align-items: flex-start;
    margin-top: 15px;
  }
  @media (min-width: ${medias.desktopSm}) {
    flex-direction: row;
    > div {
      width: calc(100% - 212px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 192px;
      margin-top: 0;
    }
  }
`;
export const DetailsRows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    display: flex;
  }
  @media (min-width: ${medias.desktopSm}) {
    flex-direction: row;
    //align-items: center;
    justify-content: flex-start;
    > div {
      margin-right: 20px;
    }
  }
`;
export const DetailsCells = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 15px;
  color: #888888;
  :not(:last-child) {
    margin-right: 20px;
  }
  b {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    margin-bottom: 5px;
    color: #1e1e1e;
  }
  @media (min-width: ${medias.desktopSm}) {
    margin-bottom: 0;
  }
`;
export const CorrectionsDetails = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
`;

export const CorrectionsTitle = styled.div`
  width: 100%;
  font-family: Blinker;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 20px;
`;
export const CorrectionsCardsDiv = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
export const CorrectionCard = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const CorrectionHeader = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
    width: auto;
    height: 24px;
    padding: 0 5px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    svg {
      margin-right: 5px;
    }
  }
`;
export const CorrectionRow = styled.div`
  width: 100%;
  display: flex;
  //align-items: center;
  padding-top: 15px;
  flex-direction: column;
  @media (min-width: ${medias.desktopSm}) {
    flex-direction: row;
  }
`;
export const CorrectionCell = styled.div`
  min-height: 100%;
  min-width: 124px;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #888888;
  :not(:last-child) {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  b {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    margin-bottom: 5px;
    color: #1e1e1e;
  }
  @media (min-width: ${medias.desktopSm}) {
    margin-bottom: 0;
  }
`;
export const AuthCallButton = styled.button`
  width: 173px;
  height: 24px;
  color: #fff;
  background: #f38a00;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16;
  text-align: left;
  margin-right: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export const SeePhotsButton = styled.button`
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  width: 100px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1e1e1e;

  svg {
    margin-right: 5px;
  }
`;
//fim
//chamado35
export const NovoChamadoModal = styled.div`
  background-color: #fff;
  border-radius: 8px;
  @media (min-width: ${medias.desktopSm}) {
    width: 1170px;
    height: 100%;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
`;
export const GoBackButton = styled.button`
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const ChamadoTitle = styled.div`
  font-family: Blinker;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #f38a00;
`;
export const FormWrapper = styled.form``;
export const StyledGridColumn = styled(GridColumn)``;

export const Loading = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #888888;
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Criando o componente SVG estilizado
export const RotatingCircle = styled(LoadingIcon)`
  animation: ${rotate} 2s linear infinite;
  width: 56px !important;
  height: 56px !important;
`;
